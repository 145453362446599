<template>
  <div>
    <v-tooltip right> </v-tooltip>
    <v-btn icon color="grey darken-2" @click="dialog = true">
      <v-icon>mdi-eye</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <p class="my-0 h3 font-weight-bold">Feriados</p>
          <p class="body-1">
            Aquí podras ver todas las fechas seleccionadas como feriados.
          </p>
        </v-card-title>
        <v-card-text>
          <v-chip v-for="(fecha, i) in arrayFechas" :key="i" class="mr-2">
            {{ formatDate(fecha) }}
          </v-chip>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formatDate from "@/utils/formatDate.js";
export default {
  name: "VerFeriados",
  props: { fechas: String },
  data() {
    return {
      dialog: false,
      formatDate,
    };
  },
  computed: {
    arrayFechas() {
      return this.fechas?.split(",") || [];
    },
  },
};
</script>

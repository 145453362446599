import axios from 'axios';
import './axiosInterceptor';
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Configuraciones = {
  //listar configuracion 
  getConfiguraciones(params) {
    return axios.get(PASARELA + "/administracion/configuraciones", { params })
  },

  //consultar configuracion por id 
  getConfiguracionesID(params) {
    return axios.get(PASARELA + "/administracion/configuracion", { params })
  },

  // crer configuracion
  crearConfiguracion(payload) {
    return axios.post(PASARELA + "/administracion/configuracion", payload)
  },

  // actualizar terminos y condiciones 
  actualizar_termino_send_email(payload) {
    return axios.put(PASARELA + "/administracion/configuracion-send-email", payload)
  },

  // actualizar configuracion
  actualizar_configuracion(payload) {
    return axios.put(PASARELA + "/administracion/configuracion", payload)
  },
  sendEmail(payload) {
    return axios.post(PASARELA + "/administracion/enviar-mail", payload)
  },
}

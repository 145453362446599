<template>
  <LayoutPage :title="pageTitle">
    <!-- DATATABLE -->
    <v-app>
      <v-main>
        <v-tabs v-model="tab">
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#Configuraciones">
            <v-icon class="mr-1">mdi-cog-outline</v-icon>Configuraciones
          </v-tab>

          <v-tab href="#Notificaciones">
            <v-icon class="mr-1">mdi-email-outline</v-icon>Notificaciones TelCo
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :value="'Configuraciones'">
            <v-card flat>
              <!-- SHOW ERROR SECTION -->
              <section v-if="errored">
                <v-alert
                  border="left"
                  class="mt-3 mx-3 elevation-3"
                  color="red lighten-2"
                  dark
                >
                  Lo sentimos, no es posible obtener la información en este
                  momento, por favor intente nuevamente mas tarde. Volver a
                  intentar
                </v-alert>
              </section>
              <!-- END SHOW ERROR SECTION -->
              <section v-else>
                <!-- FILTRO BUSCAR -->

                <v-row no-gutters>
                  <v-col class="flex justify-start mt-3 mb-5">
                    <v-row class="d-flex align-center">
                      <v-col cols="12" sm="8">
                        <v-text-field
                          v-model="search"
                          append-icon="find_in_page"
                          label="Buscar por nombre"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="auto"> </v-col>
                    </v-row>
                  </v-col>
                  <!-- END FILTRO BUSCAR -->

                  <!-- BOTON DE NUEVA CONFIGURACION -->
                  <v-col cols="6">
                    <div class="flex justify-end ">
                      <v-btn
                        dark
                        style="background: #50B565;"
                        @click="abrir_create_config()"
                      >
                        Nueva
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <!-- dialog nuevo rubro -->
                <v-dialog v-model="create_config" persistent max-width="600px">
                  <!-- CARD NUEVA CONFIGURACION -->
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">
                        Nueva Configuración
                      </span>
                      <v-spacer></v-spacer>
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="cerrar_create_config"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </template>
                      </v-menu>
                    </v-card-title>
                    <div v-if="procensando_datos">
                      <loader
                        object="#4caf50"
                        color1="#ffffff"
                        color2="#17fd3d"
                        size="5"
                        speed="2"
                        bg="#343a40"
                        objectbg="#999793"
                        opacity="40"
                        disableScrolling="false"
                        name="box"
                      ></loader>
                    </div>
                    <v-form
                      ref="formCrearConfig"
                      v-model="valid"
                      lazy-validation
                      class="mb-0"
                    >
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="new_config_nombre"
                                label="Nombre"
                                :counter="100"
                                :rules="nombreRule"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="new_config_description"
                                label="Descripción"
                                :counter="150"
                                :rules="descripcionRule"
                                required
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                              <div v-if="nombreedit == 'TERMINOS_CONDICIONES'">
                                <vue-editor v-model="valoredit"></vue-editor>
                              </div>
                              <div v-else>
                                <v-textarea
                                  outlined
                                  v-model="new_config_value"
                                  :counter="150"
                                  :rules="valorRule"
                                  label="Valor"
                                  required
                                ></v-textarea>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="cerrar_create_config"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          :disabled="!valid"
                          color="blue darken-1"
                          text
                          @click="crear_configuracion"
                        >
                          Crear
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>

                <!-- DATOS DE LA TABLA -->
                <v-data-table
                  :search="search"
                  :options.sync="options"
                  :server-items-length="totalDesserts"
                  :headers="headers"
                  :items="desserts"
                  :loading="cargando"
                  :footer-props="footerProps"
                  disable-sort
                  hide-default-footer
                  loading-text="Cargando... Espere por favor"
                  no-results-text="No se encontraron registros"
                  no-data-text="No se encontraron registros"
                  class="elevation-0 trow"
                >
                  <template v-slot:[`item.Valor`]="{ item }">
                    {{ valor(item) }}
                  </template>
                  <!-- ACCIONES BOTONES -->
                  <template v-slot:top>
                    <!-- modal editar configuracion  -->
                    <v-dialog v-model="dialog" max-width="850px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">
                            Editar configuración {{ nombreedit }}
                          </span>
                          <v-spacer></v-spacer>
                          <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="close"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </template>
                          </v-menu>
                        </v-card-title>
                        <div v-if="procensando_datos">
                          <loader
                            object="#4caf50"
                            color1="#ffffff"
                            color2="#17fd3d"
                            size="5"
                            speed="2"
                            bg="#343a40"
                            objectbg="#999793"
                            opacity="40"
                            disableScrolling="false"
                            name="box"
                          ></loader>
                        </div>
                        <v-form
                          ref="form"
                          v-model="valid"
                          lazy-validation
                          class="mb-0"
                        >
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <v-textarea
                                    v-model="descripcionedit"
                                    label="Descripción"
                                    :rows="2"
                                    :counter="150"
                                    :rules="descripcionRule"
                                    required
                                  ></v-textarea>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <div
                                    v-if="nombreedit == 'TERMINOS_CONDICIONES'"
                                  >
                                    <vue-editor
                                      v-model="valoredit"
                                    ></vue-editor>
                                  </div>
                                  <div
                                    v-else-if="
                                      nombreedit == 'ESTADO_APLICACION'
                                    "
                                  >
                                    <!-- El label del switch esta bindeado con una computed property que indica si esta en mantenimiento -->
                                    <v-switch
                                      v-model="enMantenimiento"
                                      :label="messages"
                                    ></v-switch>

                                    <div v-if="enMantenimiento">
                                      <p class="text-subtitle-1">
                                        Establecer fecha y hora límite del
                                        estado en mantenimiento
                                      </p>
                                      <v-row align="center">
                                        <v-col cols="6">
                                          <v-date-picker
                                            id="mantenimiento"
                                            v-model="date"
                                            width="290"
                                            locale="es-ar"
                                          ></v-date-picker>
                                        </v-col>

                                        <v-col cols="6">
                                          <v-text-field
                                            v-model="time"
                                            label="Elegir hora y minuto"
                                            type="time"
                                            suffix="H/M"
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                    </div>

                                    <v-alert
                                      v-if="!enMantenimiento"
                                      dense
                                      text
                                      type="success"
                                      width="50%"
                                    >
                                      El estado de la aplicación es
                                      <strong>activo</strong>
                                    </v-alert>
                                  </div>
                                  <div v-else>
                                    <v-textarea
                                      outlined
                                      v-model="valoredit"
                                      :counter="150"
                                      :rules="valorRuleFecha"
                                      label="Valor"
                                      required
                                      v-show="nombreedit !== 'FERIADOS'"
                                    >
                                    </v-textarea>
                                    <v-combobox
                                      v-model="labelFechasFeriados"
                                      multiple
                                      chips
                                      label="Fechas"
                                      item-text="label"
                                      readonly
                                      v-show="nombreedit === 'FERIADOS'"
                                      ><template v-slot:append>
                                        <v-menu
                                          v-model="menu"
                                          :close-on-content-click="false"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template
                                            v-slot:activator="{
                                              on,
                                              attrs,
                                            }"
                                          >
                                            <v-btn icon v-on="on" v-bind="attrs"
                                              ><v-icon>mdi-calendar</v-icon>
                                            </v-btn>
                                          </template>
                                          <v-date-picker
                                            v-model="fechasFeriados"
                                            multiple
                                            locale="es"
                                            no-title
                                            class="datePickerFeriados"
                                            dark
                                            @input="actualizarFechasFeriados()"
                                          ></v-date-picker>
                                        </v-menu> </template
                                    ></v-combobox>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancelar
                            </v-btn>
                            <v-btn
                              :disabled="!valid"
                              color="blue darken-1"
                              text
                              @click="actualizar"
                            >
                              Aceptar
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </template>
                  <!-- BOTONES DE LA TABLA -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-row>
                      <v-btn icon @click="editItem(item)">
                        <v-icon color="orange">
                          create
                        </v-icon>
                      </v-btn>
                      <!-- DIALOG PARA VER LAS FECHAS SELECCIONADAS -->
                      <VerFeriados
                        :fechas="item.Valor"
                        v-if="item.Nombre === 'FERIADOS'"
                      />
                    </v-row>
                  </template>
                  <!--END  BOTONES DE LA TABLA -->
                  <template v-slot:footer>
                    <v-row class="no-gutters mt-2 mx-2">
                      <v-flex xs12>
                        <span>Mostrar</span>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              dark
                              text
                              color="primary"
                              class="ml-2"
                              v-on="on"
                            >
                              {{ rowsPerPage }}
                              <v-icon>arrow_drop_down</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(number, index) in rowsPerPageArray"
                              :key="index"
                              @click="updateRowsPerPage(number)"
                            >
                              <v-list-item-title>{{
                                number
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-flex>
                    </v-row>
                  </template>
                </v-data-table>
                <!-- END DATA TABLE -->
                <div class="text-center pt-2">
                  <v-pagination
                    v-model="pagination.page"
                    :length="pagination.total"
                    :total-visible="pagination.visible"
                    @input="onPageChange"
                  ></v-pagination>
                </div>
              </section>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'Notificaciones'">
            <v-card flat>
              <TelCoCorreo />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-main>
    </v-app>
  </LayoutPage>
</template>
<script>
import { Configuraciones } from "@/services/configuraciones.js";
import { Clientes } from "@/services/clientes.js";
import { VueEditor } from "vue2-editor";
import TelCoCorreo from "./TelCoCorreo.vue";
import moment from "moment";
import VerFeriados from "./Configuraciones/VerFeriados.vue";
import LayoutPage from "../LayoutPage.vue";

export default {
  name: "Configuraciones",
  components: {
    VueEditor,
    VerFeriados,
    TelCoCorreo,
    LayoutPage,
  },
  data: () => ({
    tab: "Configuraciones",
    options: {},
    totalDesserts: 0,
    clientes: [],
    time: "12:30",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    enMantenimiento: false,
    new_config_nombre: "",
    new_config_description: "",
    new_config_value: "",
    create_config: false,
    cantidad_config: 0,
    cargando: false,
    dialog: false,
    dialogDelete: false,
    // mostrar error de api
    errored: false,
    // res indica el resultado de las validaciones
    res: false,
    //loaders para procesar datos dee envio
    procensando_datos: false,
    // Filter models.
    search: "",
    headers: [
      { text: "Nombre", value: "Nombre" },
      { text: "Descripción", value: "Descripcion", width: "400px" },
      { text: "Valor", value: "Valor" },
      { text: "Acciones", value: "actions" },
    ],
    // Estas propiedades customizan el footer por default(valores por defecto del datatable)
    footerProps: {
      "items-per-page-options": [],
      "items-per-page-text": "",
      "prev-icon": "",
      "next-icon": "",
      "page-text": "",
    },
    // paginacion api
    pagination: {
      page: 1,
      total: 0,
      visible: 7,
    },
    //  numero de registros por pagina
    rowsPerPageArray: [10, 20, 30],
    rowsPerPage: 10,
    desserts: [],
    // editar configuracion PUT
    IDedit: "",
    nombreedit: "",
    descripcionedit: "",
    valoredit: "",
    valid: false,
    nombreRule: [
      (v) => !!v || "Nombre es requerido",
      (v) =>
        (v && v.length <= 100) ||
        "Descripción no debe superar los 100 caracteres",
      (v) => /^[A-Z\s]+$/.test(v) || "Solo letras mayúsculas",
    ],
    descripcionRule: [
      (v) => !!v || "Descripción es requerido",
      (v) =>
        (v && v.length <= 150) ||
        "Descripción no debe superar los 150 caracteres",
    ],
    valorRule: [
      (v) => !!v || "Valor es requerido",
      (v) =>
        (v && v.length <= 150) ||
        "Descripción no debe superar los 150 caracteres",
    ],

    valorRuleFecha: [
      (v) => !!v || "Valor es requerido",
      (v) =>
        (v && v.length <= 600) ||
        "Descripción no debe superar los 600 caracteres",
    ],

    menu: false, // date picker
    fechasFeriados: [],
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // Ante un cambio de valor en la variable search, se dispara la busqueda de datos por contenido de esa variable al backend
    search(search_value) {
      const query = search_value;

      if (query.length >= 3) {
        this.getdatos(query);
      } else if (query.length == 0) {
        this.getdatos();
      }
    },
    options: {
      handler() {
        this.getdatos();
      },
      deep: true,
    },
  },

  created() {
    this.getdatos();
    this.getClientes();
  },
  computed: {
    format_name() {
      if (this.new_config_nombre) {
        //quitar espacios en extremos, reemplazar espacios por guien bajo, mayusculas
        return this.new_config_nombre
          .trim()
          .replace(/\s/g, "_")
          .toUpperCase();
      }
      return "";
    },
    messages() {
      let mensaje = "";
      if (this.valoredit == "sin valor") {
        mensaje = "En Mantenimiento";
      } else {
        mensaje = "En Mantenimiento, hasta el " + this.valoredit;
      }

      return this.enMantenimiento ? mensaje : "Mantenimiento";
    },

    labelFechasFeriados() {
      return this.fechasFeriados.map((fecha) =>
        moment(fecha).format("DD-MM-YYYY")
      );
    },
    pageTitle() {
      return this.tab === "Configuraciones"
        ? "Configuraciones"
        : "Notificaciones TelCo";
    },
  },
  methods: {
    // cargar todos los clientes
    getClientes() {
      const params = {
        CargarImpuestos: false,
        CargarCuentas: false,
        CargarRubros: false,
      };
      Clientes.getClientes(params).then((response) => {
        if (response.data.data == null) {
          this.clientes = [];
        } else {
          this.clientes = response.data.data;
        }
      });
    },
    cerrar_create_config() {
      this.$refs.formCrearConfig.reset();
      this.$refs.formCrearConfig.resetValidation();
      this.create_config = false;
    },
    abrir_create_config() {
      this.create_config = true;
    },
    // Recibe un parametro de busqueda por defecto en vacio. si cambia la variable search se busca por el valor Nombre en una consulta Like al backend
    getdatos: function(search_value = "") {
      const params = {
        Number: this.pagination.page,
        Size: this.rowsPerPage,
        Nombre: search_value,
      };
      this.cargando = true;
      Configuraciones.getConfiguraciones(params)
        .then((response) => {
          if (response.data.data == null) {
            this.desserts = [];
            this.pagination.page = 1;
            this.pagination.total = 0;
            this.cargando = false;
          } else {
            this.desserts = response.data.data;
            this.totalDesserts = this.desserts.length;
            this.pagination.page = response.data.meta.page.currentPage;
            this.pagination.total = response.data.meta.page.lastPage;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },
    // paginar
    onPageChange() {
      this.getdatos();
    },
    // registros por paginas
    updateRowsPerPage(number) {
      this.rowsPerPage = number;
      this.pagination.page = 1;
      this.getdatos();
    },
    //consultar los datos del rubro a modificar
    editItem(item) {
      this.consultar_configuracion(item);
      this.dialog = true;
    },

    //crear nueva configuracion
    crear_configuracion() {
      var payload = {
        nombre: this.format_name,
        descripcion: this.new_config_description,
        valor: this.new_config_value,
      };
      if (!this.$refs.formCrearConfig.validate()) {
        return; // se usa para salir de la funcion sin hacer nada
      }
      this.procensando_datos = true;
      Configuraciones.crearConfiguracion(payload)
        .then(() => {
          this.dialog = false;
          this.$toastr.s("Nueva configuracion creada exitosamente");
          this.getdatos();
          this.procensando_datos = false;
          this.cerrar_create_config();
        })
        .catch((error) => {
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.procensando_datos = false));
    },
    //confirmar actualizar los datos de configuracion
    actualizar() {
      var payload = {
        Id: this.IDedit,
        nombre: this.nombreedit,
        descripcion: this.descripcionedit,
        valor: this.valoredit,
      };

      if (this.enMantenimiento) {
        payload.valor = moment.utc(this.date + " " + this.time).format(); // la aplicacion se pone en estado mantenimiento
      } /*  else {
        payload.valor = "sin valor"; // sin valor indica que la app esta activa
      } // **** preguntar lógica *****
 */
      this.validate();
      if (this.res === true) {
        this.error = false;

        this.verificar_envio(this.nombreedit, payload);
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envio");
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    // validar formulario actualizar
    validate() {
      this.$refs.form.validate();
      this.res = this.$refs.form.validate();
    },

    verificar_envio(params, payload) {
      this.procensando_datos = true;
      if (params == "TERMINOS_CONDICIONES") {
        Configuraciones.actualizar_termino_send_email(payload)
          .then(() => {
            this.dialog = false;
            this.$toastr.s("Términos y condiciones se actualizo correctamente");
            // actualziar tabla de clientes
            this.getdatos();
            this.procensando_datos = false;
            this.close();
          })
          .catch((error) => {
            this.errored = true;
            var data = error.response.data;
            this.error = true;
            this.$toastr.e(data.message);
          })
          .finally(() => (this.procensando_datos = false));
      } else {
        Configuraciones.actualizar_configuracion(payload)
          .then(() => {
            // actualizar el esatdo de la app
            if (params == "ESTADO_APLICACION") {
              this.$store.dispatch("getEstadoAplicacion");
              // aca necesito enviar solo cuando se pone en mantenimiento, y no cuando vuelve a estado activo
              // el atributo valor del payload con una fecha indica un intento de mantenimiento
              if (payload.valor != "sin valor") {
                this.sendEmail();
              }
            }
            this.dialog = false;
            this.$toastr.s(
              "La configuración " + params + " se actualizo correctamente"
            );

            this.getdatos();
            this.procensando_datos = false;

            this.close();
          })
          .catch((error) => {
            this.errored = true;
            var data = error.response.data;
            this.error = true;
            this.$toastr.e(data.message);
          })
          .finally(() => (this.procensando_datos = false));
      }
    },
    /**
     * Envia correo de avis por mantenimiento a cada cliente
     */
    sendEmail() {
      this.clientes.forEach((client) => {
        // preparar la data para el email de un cliente
        var dataEmail = {
          Email: [client.email],
          Asunto: "Aviso Importante de Wee!.",
          Nombre: client.razon_social,
          Mensaje:
            "La aplicación estará en mantenimiento hasta el: #0, aproximadamente #1",
          CamposReemplazar: [
            moment.utc(this.date).format("DD/MM/YYYY"),
            this.time,
          ],
          TipoEmail: "template",
        };
        Configuraciones.sendEmail(dataEmail)
          .then((response) => {
            // console.log(response.data.message);
          })
          .catch((error) => {
            // console.error("Error al enviar email:", error);
          });
      });
    },
    //consultar configuracion por id
    consultar_configuracion: function(item) {
      const params = {
        nombre: item.Nombre,
      };
      this.procensando_datos = true;
      Configuraciones.getConfiguracionesID(params)
        .then((response) => {
          //cargar los datos en el modal
          this.IDedit = response.data.Id;
          this.nombreedit = response.data.Nombre;
          this.descripcionedit = response.data.Descripcion;
          this.valoredit = response.data.Valor;

          if (this.nombreedit == "ESTADO_APLICACION") {
            if (this.valoredit == "sin valor") {
              this.enMantenimiento = false;
            } else {
              this.enMantenimiento = true;
            }
          }

          //almacenamos las fechas en un vector
          if (this.nombreedit === "FERIADOS" && this.valoredit.length > 0) {
            this.fechasFeriados = this.valoredit.split(",");
          }
        })
        .catch((error) => {
          var data = error.response.data;
          //mostrar mensaje de error en caso que la variable sea true(tenga datos)
          this.error = true;
          this.msg = data.message;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.procensando_datos = false));
    },

    actualizarFechasFeriados() {
      this.valoredit = this.fechasFeriados.join(",");
    },

    // cortar valor de tabla
    valor(item) {
      if (item.Nombre == "ESTADO_APLICACION") {
        // console.log(item.Valor);
        return item.Valor == "sin valor"
          ? item.Valor
          : moment.utc(item.Valor).format("DD-MM-YYYY HH:mm");
      }
      return item.Valor.slice(0, 50);
    },
    // error de api , esta funcion vuelve a recargar la pagina
    intento() {
      this.$router.go();
    },
  },
};
</script>
<style scoped>
a {
  color: #f7f7f7;
}

.v-input .v-label {
  height: 15px;
  line-height: 20px;
  letter-spacing: normal;
}
.v-label {
  font-size: 14px;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.datePickerFeriados ::v-deep .v-btn--active {
  color: greenyellow; /* Color de fondo de las fechas seleccionadas */
}
</style>
